import React, { useEffect } from "react";
import { Header } from "./components";
import { Footer } from "./components/Footer/Footer";
import { SlideContainer } from "./components/SlideContainer";
import { Page_1 } from "./pages/home/sections/Page_1";
import { Page_2 } from "./pages/home/sections/Page_2";
import { Page_3 } from "./pages/home/sections/Page_3";
import { Page_4 } from "./pages";

function App() {
  return (
    <div className="relative w-[92%] mx-auto full-heigh-page min-h-[100vh]">
      <Header />
      <SlideContainer>  
        <Page_4 index={0} />
        <Page_3 index={1} />
        <Page_2 index={2} />
        <Page_1 index={3} />
      </SlideContainer>
      <Footer />
      
    </div>
  );
}

export default App;
