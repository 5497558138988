import React from "react";
import styles from "../home.module.css";
import infinity from "../../../assets/page1_main.png";
import solana from "../../../assets/solana-sol-logo.svg";
import mvx from "../../../assets/multiversx-egld-egld-logo.png";
import ParticlesBg from "particles-bg";
import { Slide } from "components/Slide";

export const Page_1 = ({index}:{index:number;}) => {
  return (
    <Slide className={`relative w-full slide_${index}`}>
      <div className="relative flex flex-col items-center justify-center h-full">
        <div className="relative justify-center h-full">
          <img
            src={infinity}
            alt=""
            className="z-0 opacity-75 max-w-[100%] max-h-[100%] mt-0"
          />        
          <ParticlesBg type="thick" bg={true} />
        </div>
      </div>
      <div className={`${styles.inner_card} w-full flex-row absolute bottom-0`}>
        <a
          className="flex w-full"
          href="https://solscan.io/token/9yLAYZ4FUdAvuy3nWRjLQfza5sqLz34ywtABk4s87BiA"
          target="_blank"
          rel="noreferrer"
        >
          <img src={solana} alt="solana" className="w-[31px] mr-[20px]" />
          <div className="text-sm md:text-lg lg:text-xl xl:text-2xl">
            9yLAYZ4FUdAvuy3nWRjLQfza5sqLz34ywtABk4s87BiA
          </div>
        </a>
        <a
          className="flex w-full"
          href="https://explorer.multiversx.com/tokens/MOOSE-ba522f"
          target="_blank"
          rel="noreferrer"
        >
          <img src={mvx} alt="Multiversx" className="w-[31px] mr-[20px]" />
          <div className="text-sm md:text-lg lg:text-xl xl:text-2xl">
            MOOSE-ba522f
          </div>
        </a>
      </div>
    </Slide>
  );
};
