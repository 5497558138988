import React from "react";
import logo from "../../assets/logo_moose.png";
import styles from './styles.module.css';
import { Burger } from "./Burger";

export const Header = () => {
    return(
        <header className={`flex justify-between h-[20vh]`}>
            <div className="left flex items-center">
                <div><img src={logo} alt="LOGO" height="25"/></div>
                <div className={`${styles.evm_compatible} flex items-center justify-center ml-5`}>
                    <span className={styles.evm_compatible_text}>
                        <strong>JOINT THE MOOSE 🌿</strong>
                    </span>
                </div>
                {/* <div className={`${styles.evm_compatible} flex items-center justify-center ml-5`}>
                    <span className={styles.evm_compatible_text}>
                        <strong><a href ="https://birdeye.so/token/9yLAYZ4FUdAvuy3nWRjLQfza5sqLz34ywtABk4s87BiA?chain=solana" target="blank">Solana JOINT 🌿</a></strong>
                    </span>
                </div>
                <div className={`${styles.evm_compatible} flex items-center justify-center ml-5`}>
                    <span className={styles.evm_compatible_text}>
                        <strong><a href ="https://birdeye.so/token/9yLAYZ4FUdAvuy3nWRjLQfza5sqLz34ywtABk4s87BiA?chain=solana" target="blank">🥦 Bridge 🥦</a></strong>
                    </span>
                </div>
                <div className={`${styles.evm_compatible} flex items-center justify-center ml-5`}>
                    <span className={styles.evm_compatible_text}>
                        <strong><a href ="https://swap.onedex.app/swap?firstToken=EGLD&secondToken=MOOSE-ba522f" target="blank">MultiversX JOINT 🌿</a></strong>
                    </span>
                </div> */}
            </div>
            <Burger />
        </header>
    )
}
