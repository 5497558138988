import React from "react";
import twitter from "../../assets/social/twitter.svg";
import telegram from "../../assets/social/telegram.png";


export const Footer = () => {
  return (
    <div className="flex justify-between w-full h-[5vh]">
      <div>2024 © The Moose</div>
      <div className="flex gap-[16px]">
        <a
          href="https://twitter.com/TheMoose_Sol"
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitter} alt="twitter" />
        </a>
        <a
          href="https://t.me/themooseofficial_Sol"
          target="_blank"
          rel="noreferrer"
        >
          <img src={telegram} alt="telegram" width="24" />
        </a>
      </div>
    </div>
  );
};
