import { useState } from "react";
import styles from "./burger.module.css";

export const Burger = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`${styles.menu} flex z-50 ${
        isOpen
          ? "absolute right-[0vw] top-[3vh] w-[20vh] h-[30vh] " + styles.opened
          : "items-center"
      }`}
    >
      <button
        className="lg:hidden flex items-center px-3 py-2 border rounded text-gray-500 border-gray-500"
        onClick={toggleMenu}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          {isOpen ? (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          ) : (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          )}
        </svg>
      </button>
      <div
        className={`${styles.option_container} lg:block ${
          isOpen ? "flex w-full flex-col items-right mt-[10vh]" : "hidden"
        }`}
      >
        <a className={`block lg:inline-block ${isOpen ? styles.link_opened: ""}`} href="https://birdeye.so/token/9yLAYZ4FUdAvuy3nWRjLQfza5sqLz34ywtABk4s87BiA?chain=solana" target="_blank" rel="noreferrer">
          Solana 🌿
        </a>
        <a className={`block lg:inline-block ${isOpen ? styles.link_opened: ""}`} href="https://bridge.onefinity.network/" target="_blank" rel="noreferrer">
          Bridge 🥦
        </a>
        <a className={`block lg:inline-block ${isOpen ? styles.link_opened: ""}`} href="https://swap.onedex.app/swap?firstToken=EGLD&secondToken=MOOSE-ba522f" target="_blank" rel="noreferrer">
          MultiversX 🌿
        </a>
      </div>
    </div>
  );
};
