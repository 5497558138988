import React, { useEffect, useRef, useState } from "react";
import upArrow from "../assets/up_arrow.gif";
import downArrow from "../assets/down_arrow.gif";


type statusType = {
  scrolling: boolean;
  currentSlide: number;
};
export const SlideContainer = ({
  children,
  className,
}: {
  children: any;
  className?: string;
}) => {
  const [status, setStatus] = useState<statusType>({
    scrolling: false,
    currentSlide: 3,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  const showSlide = (index: number) => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const visibleImages = container.querySelectorAll(".slide");
    if (index < visibleImages.length && index >= 0) {
      const nextImage = visibleImages[index] as HTMLElement;
      nextImage.scrollIntoView(true);
    }
  };

  useEffect(() => {
    showSlide(status.currentSlide);
  }, []);

  const handleScroll = (e: any) => {
    if (!status.scrolling) {
      const newSlide = status.currentSlide === 0 ? 0 : status.currentSlide - 1;
      setStatus({
        currentSlide: newSlide,
        scrolling: true,
      });
      showSlide(Math.trunc(newSlide));
      setTimeout(() => {
        setStatus({
          currentSlide: newSlide,
          scrolling: false,
        });
        console.log("Current slide:", newSlide);
      }, 300);
    }
  };

  return (
    <div className={`relative ${className ?? ""}`}>
      {status.currentSlide == 0 && (
        <div className="absolute bottom-10 right-0 flex items-center text-white bg-transparent p-2 z-[100]">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              showSlide(3);
              setStatus({
                currentSlide: 3,
                scrolling: false,
              });
            }}
          >
            <img
              src={downArrow}
              alt="up"
              className="mr-2 w-1/2 sm:w-6 md:w-10 lg:w-14 xl:w-18"
            />
          </a>
        </div>
      )}
      <div
        ref={containerRef}
        className="overflow-hidden h-[75vh]"
        onWheel={handleScroll}
        onTouchMove={handleScroll}
      >
        {children}
      </div>
      <div className="absolute bottom-10 right-0 flex items-center text-white bg-transparent p-2">
        {status.currentSlide !== 0 && (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleScroll(0);
            }}
          >
            <img
              src={upArrow}
              alt="up"
              className="mr-2 w-1/2 sm:w-6 md:w-10 lg:w-14 xl:w-18"
            />
          </a>
        )}
      </div>
    </div>
  );
};

