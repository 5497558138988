import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App /> 
  </React.StrictMode>
);

// Función para desplazar la página hacia abajo
const scrollToBottom = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth' // Desplazamiento suave
  });
}

// Llamar a la función después de que el contenido se haya cargado completamente
window.onload = scrollToBottom;

