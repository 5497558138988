import React from "react";

export const Slide = ({ children, className, }: { children: any, className?:string; }) => {
  return (
    <div className={`${className ?? ''} slide mh-[75vh] h-[75vh]`} >
      {children}
    </div>
  );
};

